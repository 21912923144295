<template>
<b-card :title="$t('delivery_statuses')">
  <b-row>
    <b-col md="12" class="my-1">
      <div class="text-right">
        <router-link :to="{ name: 'statuses' }" class="btn btn-sm btn-success">{{ $t('add') }}</router-link>
        <!--<b-button variant="success" size="sm" id="show-btn" @click="$bvModal.show('statuses-modal')">{{ $t('add') }}</b-button>-->
      </div>
    </b-col>
  </b-row>
  <b-row>
    <b-col md="12">
      <b-table responsive striped hover show-empty :items="items" :fields="fields" :current-page="currentPage" :per-page="0">
        <template v-slot:cell(actions)="row">
            <b-button-group>
                <!--
                  <router-link :to="{ name: 'statuses.edit', params: { 'id': row.item.id } }" class="btn btn-sm btn-warning"><fa icon="pencil-alt" /></router-link>
                <b-button size="sm" class="mr-1" variant="warning" @click="info(row.item, row.index, $event.target)">
                  <fa icon="pencil-alt" />
                </b-button>
                -->
                <router-link :to="{ name: 'statuses.edit', params: { id: row.item.status_id } }" class="btn btn-sm btn-warning"><fa icon="pencil-alt" /></router-link>
                <b-button size="sm" variant="danger" @click="removeItem(row.item.id)">
                <fa icon="trash" />
                </b-button>
            </b-button-group>
        </template>
      </b-table>
    </b-col>
  </b-row>
  <b-modal id="statuses-modal" hide-footer>
    <form @submit.prevent="add" @keydown="form.onKeydown($event)" class="create-form">
      <div class="form-group">
        <label for="">{{ $t('name') }}</label>
        <input type="text" name="name" class="form-control form-control-sm" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }" :placeholder="$t('name')">
        <has-error :form="form" field="name" />
      </div>
      <div class="form-group">
        <label for="">{{ $t('code') }}</label>
        <input type="text" name="code" class="form-control form-control-sm" v-model="form.code" :class="{ 'is-invalid': form.errors.has('name') }" :placeholder="$t('code')">
        <has-error :form="form" field="code" />
      </div>
      <div class="form-group">
        <label for="">{{ $t('delivery_type') }}</label>
        <select class="form-control" v-model="form.delivery_id" :class="{ 'is-invalid': form.errors.has('delivery_id') }">
          <option v-for="item in deliveries" :key="item.id" :value="item.id">{{ item.name }}</option>
          <has-error :form="form" field="delivery_id" />
        </select>
      </div>
      <div class="form-group">
        <label for="">{{ $t('statuses') }}</label>
        <select class="form-control" v-model="form.status_id" :class="{ 'is-invalid': form.errors.has('status_id') }">
          <option v-for="item in statuses" :key="item.id" :value="item.id">{{ item.name }}</option>
          <has-error :form="form" field="status_id" />
        </select>
      </div>
      <button type="submit" class="btn btn-success btn-sm" :disabled="form.busy">{{ $t('save') }}</button>
    </form>
  </b-modal>
</b-card>
</template>
<script>
import axios from 'axios'
import Form from 'vform'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('delivery_statuses_crm') }
    },
    data: () => ({
      form: new Form({
        id: '',
        name: '',
        code: '',
        delivery_id: '',
        status_id: ''
      }),
      items: [],
      fields: [
        {
          key: 'delivery_type',
          label: 'Тип доставки'
        },
        {
          key: 'name',
          label: 'Статус доставки'
        },
        {
          key: 'code',
          label: 'Код доставки',
          class: 'text-center'
        },
        {
          key: 'status',
          label: 'Статус СРМ'
        },
        {
          key: 'actions',
          label: 'Дії',
          class: 'text-right'
        }
      ],
      statusModal: {
        id: 'statuses-modal',
        title: '',
        content: ''
      },
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      deliveries: null,
      statuses: null
    }),
    mounted () {
        this.fetchData().catch(error => {
            console.log(error)
        })
        this.fetchDeliveries()
        this.fetchStatuses()
    },
    methods: {
      add () {
        if (this.form.id) {
          this.updateStatus()
        } else {
          this.createStatus()
        }
      },
      async createStatus () {
        this.form.busy = true
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await this.form.post(apiUrl + 'delivery-statuses-crm').then(resp => {
          // this.$router.push({ name: 'deliveries.statuses' })
          this.fetchData()
          this.$root.$emit('bv::hide::modal', this.statusModal.id)
          this.form.reset()
        }).catch(error => {
          this.form.errors.set(error.response.data.error.errors)
        })
      },
      async updateStatus () {
        this.form.busy = true
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const id = this.form.id
        await this.form.put(apiUrl + 'delivery-statuses-crm/' + id).then(resp => {
          this.fetchData()
          this.$root.$emit('bv::hide::modal', this.statusModal.id)
          this.form.reset()
        }).catch(error => {
          this.form.errors.set(error.response.data.error.errors)
        })
      },
      async fetchData () {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'delivery-statuses-crm')
        this.items = data.data
      },
      async removeItem (id) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_delete'))
        if (result) {
          await axios.delete(apiUrl + 'delivery-statuses-crm/' + id)
          this.fetchData()
          this.$root.$refs.loading.finish()
        }
      },
      async fetchDeliveries () {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'deliveries')
        this.deliveries = data.data
      },
      async fetchStatuses () {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'statuses')

        this.statuses = data.data.filter(s => s.type === 'order')
      },
      info (item, index, btn) {
        this.form.fill(item)
        this.$root.$emit('bv::show::modal', this.statusModal.id, btn)
      },
      resetStatusModal () {
        this.statusModal.title = ''
        this.statusModal.content = ''
      }
    },
    watch: {
      currentPage: {
        handler: function (value) {
          this.fetchData().catch(error => {
            console.log(error)
          })
        }
      }
    }
}
</script>
